<template>
  <div>
    <component :is="currentScreen" />
  </div>
</template>

<script>
import HomeScreenMobile from '@/views/HomeScreenMobile.vue';
import HomeScreenDesktop from '@/views/HomeScreenDesktop.vue';

export default {
  name: 'HomeScreen',
  metaInfo() {
    return {
      title: "Home - My Vue App",  // Page ka title
      meta: [
        { name: "description", content: "This is the homepage of My Vue App" },  // Meta description
        { name: "keywords", content: "home, vue, seo, app" },  // Keywords
        { property: "og:title", content: "Home - My Vue App" },  // Open Graph title (for social media sharing)
        { property: "og:description", content: "This is the homepage of My Vue App" },  // Open Graph description
      ],
    };
  },
  components: {
    HomeScreenMobile,
    HomeScreenDesktop,
  },
  computed: {
    currentScreen() {
      // स्क्रीन साइज़ के अनुसार कंपोनेंट चुनें
      return window.innerWidth <= 768 ? 'HomeScreenMobile' : 'HomeScreenDesktop';
    },
  },
};
</script>
