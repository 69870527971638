<template>
    <div class="service-page">
      <div class="service-container">
       <!-- Left Side: Why Choose Us -->
       <WhyChooseUs /> 
        <!-- Right Side: Service Details -->
        <div class="service-content-wrapper">
          <div class="service-image">
            <img src="@/assets/service/chimney/taital.png" alt="AC Repair" />
          </div>
          <div class="service-content">
            <h2 class="service-title">Chimney Repair Service</h2>
            <p class="service-provider">
              <strong>Technician:</strong> ★ 4.9 (200 Reviews)
            </p>
            <div class="service-price"> Starting Price
              <span class="discount-price">249 /-</span>
              <span class="original-price">350 /-</span>
            </div>

            <div class="ac-service-row">
    <div class="ac-service-card">
      <div class="ac-service-image">
        <img src="@/assets/service/chimney/narmal chimney.webp" alt="Narmal Chimney" />
      </div>
      <div class="ac-service-name">
        <h3>Narmal Chimney</h3>
      </div>
    </div>
    <div class="ac-service-card">
      <div class="ac-service-image">
        <img src="@/assets/service/chimney/haydrolic.avif" alt="haydrolic Chimney" />
      </div>
      <div class="ac-service-name">
        <h3>haydrolic Chimney</h3>
      </div>
    </div>
  </div>
    
  <div class="top-service">
    <div v-for="(service, index) in services" :key="index" class="service-card">
      <img :src="service.image" :alt="service.name" class="service-image-detelas" />
      <div class="service-details">
        <h3 class="service-name">{{ service.name }}</h3>
        <div class="service-price">
          <span class="discount-price">₹{{ service.discountPrice }}</span>
          <span class="price-name">{{ service.priceUnit }}</span>
        </div>
        <router-link to="/book">
          <button class="book-button">Book Now</button>
        </router-link>
      </div>
    </div>
  </div>


          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import WhyChooseUs from "@/components/WhyChooseUs.vue";
  export default {
    name: "ChimneyRepairService",
    components: {
    WhyChooseUs,
  },
    data() {
    return {
      services: [
        {
          name: "Chimany Repair And Cheking",
          discountPrice: 300,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/chimney/chimney-repair-service-500x500.webp"),
        },
        {
          name: "Chimney Cleaning Basic",
          discountPrice: 500,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/chimney/chimney cleaning.jpeg"),
        },
        {
          name: "Chimney Cleaning Deep",
          discountPrice: 800,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/chimney/chimany deep cleaning.png"),
        },
        {
          name: "Chimney Cleaning Deep Hydrolic",
          discountPrice: 1200,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/chimney/haydrolic.avif"),
        },
        {
          name: "Chimney Install",
          discountPrice: 400,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/chimney/chimney install.jpeg"),
        },
        {
          name: "Chimney Install + Hol",
          discountPrice: 800,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/chimney/chimney instaal hol.jpg"),
        },
        {
          name: "Chimney Un-istall",
          discountPrice: 300,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/chimney/unitall chimney.jpg"),
        },
      ],
    };
  },
  };
  </script>
