<template>
    <div class="privacy-policy-page">
      <div class="container">
        <h1 class="title">Privacy Policy</h1>
        <p class="intro">
          Welcome to Faster Home Service! Your privacy is important to us, and we are committed to safeguarding your information.
        </p>
        
        <div class="section" v-for="(section, index) in privacySections" :key="index">
          <h2 class="section-title">{{ section.title }}</h2>
          <p class="section-content" v-for="(paragraph, i) in section.content" :key="i">
            {{ paragraph }}
          </p>
        </div>
      </div>
    </div>
  </template>
<script>
export default {
  name: "PrivacyPolicy",
  data() {
    return {
      privacySections: [
        {
          title: "1. Information We Collect",
          content: [
            "We may collect personal information such as your name, email address, phone number, and service address when you interact with our platform.",
            "We also gather non-personal data such as browser type, IP address, and device information to improve our services."
          ],
        },
        {
          title: "2. How We Use Your Information",
          content: [
            "To provide and improve our services, respond to your inquiries, and process bookings.",
            "To send important updates, promotional offers, and notifications relevant to the services you have requested.",
          ],
        },
        {
          title: "3. Data Sharing and Security",
          content: [
            "Your personal information is not shared with third parties except when required to provide the requested services or comply with legal obligations.",
            "We implement advanced security measures to protect your data from unauthorized access or misuse.",
          ],
        },
        {
          title: "4. Your Privacy Rights",
          content: [
            "You have the right to access, update, or delete your personal information. Contact us at support@fasterhomeservice.com for assistance.",
            "You can opt out of receiving promotional messages by following the unsubscribe link provided in our emails."
          ],
        },
        {
          title: "5. Changes to This Privacy Policy",
          content: [
            "We may update this Privacy Policy to reflect changes in our practices or for legal and operational reasons.",
            "The updated policy will be effective immediately and will be available on our website."
          ],
        },
        {
          title: "6. Contact Us",
          content: [
            "If you have any questions or concerns about this Privacy Policy, please contact us at:",
            "Faster Home Service, support@fasterhomeservice.com, Phone: +91-6263173842"
          ],
        },
      ],
    };
  },
};
</script>
<style scoped>
.privacy-policy-page {
  padding: 20px;
  background-color: #f9f9f9;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.intro {
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
  color: #555;
}

.section {
  margin-bottom: 20px;
}

.section-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.section-content {
  font-size: 14px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 10px;
}
</style>
