<template>
  <div class="p-4 bg-gray-100 min-h-screen">
    <div class="p-4 bg-white rounded-lg shadow">
      <h1 class="text-xl font-bold">{{ service.name }}</h1>
      <img :src="service.image" alt="Service" class="w-full h-48 object-cover rounded-lg mt-4" />
      <p class="mt-4 text-gray-600">{{ service.description }}</p>
      <div class="flex justify-between items-center mt-6">
        <p class="text-lg font-bold text-purple-600">$ {{ service.price }}</p>
        <button class="px-4 py-2 bg-purple-600 text-white rounded-lg">
          Book Now
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      service: {
        id: 1,
        name: 'Plumbing Repair',
        description: 'Fix leaks and all plumbing issues.',
        price: 20,
        image: 'https://via.placeholder.com/300',
      },
    };
  },
};
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
