<template>
    <div class="service-page">
      <div class="service-container">
       <!-- Left Side: Why Choose Us -->
       <WhyChooseUs /> 
        <!-- Right Side: Service Details -->
        <div class="service-content-wrapper">
          <div class="service-image">
            <img src="@/assets/service/microwave/7666001.webp" alt="AC Repair" />
          </div>
          <div class="service-content">
            <h2 class="service-title">Microwave Oven Repair Service</h2>
            <p class="service-provider">
              <strong>Technician:</strong> ★ 4.9 (200 Reviews)
            </p>
            <div class="service-price"> Starting Price
              <span class="discount-price">249 /-</span>
              <span class="original-price">350 /-</span>
            </div>

            <div class="ac-service-row">
    <div class="ac-service-card">
      <div class="ac-service-image">
        <img src="@/assets/service/microwave/microwave.png" alt="Microwave" />
      </div>
      <div class="ac-service-name">
        <h3>Microwave</h3>
      </div>
    </div>
    <div class="ac-service-card">
      <div class="ac-service-image">
        <img src="@/assets/service/microwave/oven.webp" alt="Oven" />
      </div>
      <div class="ac-service-name">
        <h3>Oven
        </h3>
      </div>
    </div>
    <div class="ac-service-card">
      <div class="ac-service-image">
        <img src="@/assets/service/microwave/microve oven.avif" alt="Microwave Oven" />
      </div>
      <div class="ac-service-name">
        <h3>Microwave Oven
        </h3>
      </div>
    </div>
  </div>
    
  <div class="top-service">
    <div v-for="(service, index) in services" :key="index" class="service-card">
      <img :src="service.image" :alt="service.name" class="service-image-detelas" />
      <div class="service-details">
        <h3 class="service-name">{{ service.name }}</h3>
        <div class="service-price">
          <span class="discount-price">₹{{ service.discountPrice }}</span>
          <span class="price-name">{{ service.priceUnit }}</span>
        </div>
        <router-link to="/book">
          <button class="book-button">Book Now</button>
        </router-link>
      </div>
    </div>
  </div>


          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
   import WhyChooseUs from "@/components/WhyChooseUs.vue";
  export default {
    name: "MicrowaveService",
    components: {
    WhyChooseUs,
  },
    data() {
    return {
      services: [
        {
          name: "Microwave Oven Repair And Cheking",
          discountPrice: 300,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/microwave/microwave-oven-repair.webp"),
        },
        {
          name: "Microwave Oven Cleaning",
          discountPrice: 349,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/microwave/cleaning.jpg"),
        },
        {
          name: "Oven Repair",
          discountPrice: 300,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/microwave/oven.jpg"),
        },
      ],
    };
  },
  };
  </script>