import { createRouter, createWebHistory } from 'vue-router';
import HomeScreen from '@/views/HomeScreen.vue';
import ServiceDetail from '@/views/ServiceDetail.vue';
import AcRepairService from '@/views/mobail-viwe/AcRepairService.vue';
import WashingMachineService from '@/views/mobail-viwe/WashingMachineService.vue';
import FridgeRepairService from '@/views/mobail-viwe/FridgeRepairService.vue';
import RoService from '@/views/mobail-viwe/RoService.vue';
import GeyserRepairService from '@/views/mobail-viwe/GeyserRepairService.vue';
import TvInstallService from '@/views/mobail-viwe/TvInstallService.vue';
import MicrowaveService from '@/views/mobail-viwe/MicrowaveService.vue';
import ChimneyRepairService from '@/views/mobail-viwe/ChimneyRepairService.vue';
import SofaCleaningService from '@/views/mobail-viwe/SofaCleaningService.vue';
import CarpetCleaningService from '@/views/mobail-viwe/CarpetCleaningService.vue';
import MattressCleaningService from '@/views/mobail-viwe/MattressCleaningService.vue';
import GasStoveService from '@/views/mobail-viwe/GasStoveService.vue';
import WaterDispenserService from '@/views/mobail-viwe/WaterDispenserService.vue';

import AboutPage from '@/views/link-page/about-us.vue';
import TermsAndConditions from '@/views/link-page/privacy-policy.vue';
import PrivacyPolicy from '@/views/link-page/privacy-policy.vue';
import ContactPage from '@/views/link-page/contact.vue';
import DownloadApp from '@/views/link-page/DownloadApp.vue';

import NotFound from '../components/NotFound.vue';
import BookingPage from '../components/BookingPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeScreen,
    meta: {
      title: "Faster Home Service - Raipur",
      description: "Welcome to Faster Home Service, your trusted partner for fast and reliable home services in Raipur. We offer repair, maintenance, and installation services for various home appliances, providing quick and quality service at affordable prices in Raipur city.",
      keywords: "home services, repair, fast, booking, Raipur, appliance repair"
    },
  },
  {
    path: '/service/:path',
    name: 'ServiceDetail',
    component: ServiceDetail,
    props: true,
    meta: {
      title: "Service Detail - Faster Home Service Raipur",
      description: "Explore detailed information about the specific home service you need in Raipur. Whether it's an AC repair, washing machine maintenance, or a new appliance installation, we offer expert services right at your doorstep in Raipur.",
      keywords: "service details, home service, repair, Raipur, appliance services"
    },
  },
  {
    path: '/ac-repair-service',
    name: 'AcRepairService',
    component: AcRepairService,
    meta: {
      title: "AC Repair Service in Raipur - Faster Home Service",
      description: "Get your AC repaired quickly and efficiently with professional service in Raipur. Our experts ensure your air conditioner is running smoothly, offering repairs and maintenance for all AC brands in Raipur city.",
      keywords: "AC repair, home service, HVAC, Raipur, AC maintenance"
    },
  },
  {
    path: '/washing-machine-service',
    name: 'WashingMachineService',
    component: WashingMachineService,
    meta: {
      title: "Washing Machine Repair Service in Raipur",
      description: "Quick and professional washing machine repair services in Raipur. Whether it's a malfunction, noise issue, or any other problem, our technicians provide efficient repairs at affordable rates in the city of Raipur.",
      keywords: "Washing machine repair, home service, appliance repair, Raipur"
    },
  },
  {
    path: '/fridge-repair-service',
    name: 'FridgeRepairService',
    component: FridgeRepairService,
    meta: {
      title: "Fridge Repair Service in Raipur",
      description: "Need fridge repair in Raipur? Our experts offer fast, reliable fridge repair services. From cooling issues to temperature control, get your fridge fixed quickly at your doorstep in Raipur city.",
      keywords: "Fridge repair, home service, refrigerator repair, Raipur"
    },
  },
  {
    path: '/ro-service',
    name: 'RoService',
    component: RoService,
    meta: {
      title: "RO Service & Repair in Raipur",
      description: "Get your RO water purifier serviced and repaired with our trusted technicians in Raipur. We provide installation, maintenance, and repair services to ensure clean drinking water at your home in Raipur city.",
      keywords: "RO repair, water purifier, Raipur, home service"
    },
  },
  {
    path: '/geyser-repair-service',
    name: 'GeyserRepairService',
    component: GeyserRepairService,
    meta: {
      title: "Geyser Repair Service in Raipur",
      description: "Professional geyser repair services in Raipur. Whether it's a heating problem or water leakage, our technicians can fix any issues with your geyser to ensure hot water all year round in Raipur.",
      keywords: "Geyser repair, water heater repair, home service, Raipur"
    },
  },
  {
    path: '/tv-install-service',
    name: 'TvInstallService',
    component: TvInstallService,
    meta: {
      title: "TV Installation Service in Raipur",
      description: "Need help installing your new TV? Get professional TV installation services at affordable prices in Raipur. Our experts will install your TV safely and securely, ensuring the best viewing experience.",
      keywords: "TV installation, home service, entertainment, Raipur"
    },
  },
  {
    path: '/microwave-service',
    name: 'MicrowaveService',
    component: MicrowaveService,
    meta: {
      title: "Microwave Repair Service in Raipur",
      description: "Expert microwave repair services in Raipur. Whether it's not heating, making noise, or other issues, our professionals offer prompt repairs and servicing at affordable rates in Raipur city.",
      keywords: "Microwave repair, appliance repair, home service, Raipur"
    },
  },
  {
    path: '/chimney-repair-service',
    name: 'ChimneyRepairService',
    component: ChimneyRepairService,
    meta: {
      title: "Chimney Repair Service in Raipur",
      description: "Chimney cleaning and repair service in Raipur. Our technicians ensure your chimney is working efficiently, providing cleaning and maintenance services for kitchen appliances in Raipur.",
      keywords: "Chimney repair, kitchen appliances, home service, Raipur"
    },
  },
  {
    path: '/sofa-cleaning-service',
    name: 'SofaCleaningService',
    component: SofaCleaningService,
    meta: {
      title: "Sofa Cleaning Service in Raipur",
      description: "Deep cleaning service for sofas in Raipur. Our experts clean all types of sofas, removing stains, dirt, and allergens to give your furniture a fresh look and feel. Book a sofa cleaning service in Raipur today.",
      keywords: "Sofa cleaning, upholstery cleaning, home service, Raipur"
    },
  },
  {
    path: '/carpet-cleaning-service',
    name: 'CarpetCleaningService',
    component: CarpetCleaningService,
    meta: {
      title: "Carpet Cleaning Service in Raipur",
      description: "Get your carpets cleaned by professionals in Raipur. We offer deep cleaning services that remove stains, dirt, and allergens, making your carpets look new again. Available for all areas of Raipur city.",
      keywords: "Carpet cleaning, rug cleaning, home service, Raipur"
    },
  },
  {
    path: '/mattress-cleaning-service',
    name: 'MattressCleaningService',
    component: MattressCleaningService,
    meta: {
      title: "Mattress Cleaning Service in Raipur",
      description: "Professional mattress cleaning service in Raipur. Get rid of stains, dust, and allergens for a better night’s sleep. Our deep cleaning service ensures your mattress stays fresh and clean for longer.",
      keywords: "Mattress cleaning, home service, upholstery cleaning, Raipur"
    },
  },
  {
    path: '/gas-stove-service',
    name: 'GasStoveService',
    component: GasStoveService,
    meta: {
      title: "Gas Stove Repair Service in Raipur",
      description: "Need gas stove repair in Raipur? We offer fast and reliable repair services for all gas stove brands. Our technicians will ensure your stove works perfectly, providing safety and efficiency at home in Raipur.",
      keywords: "Gas stove repair, appliance repair, home service, Raipur"
    },
  },
  {
    path: '/water-dispenser-service',
    name: 'WaterDispenserService',
    component: WaterDispenserService,
    meta: {
      title: "Water Dispenser Repair Service in Raipur",
      description: "Quick water dispenser repair services in Raipur. Whether your dispenser isn’t dispensing water or has other issues, we offer reliable repairs to get it working again in Raipur city.",
      keywords: "Water dispenser repair, home service, appliance repair, Raipur"
    },
  },
  {
    path: '/about-us',
    name: 'AboutPage',
    component: AboutPage,
    meta: {
      title: "About Us - Faster Home Service Raipur",
      description: "Learn more about Faster Home Service, your trusted partner for home repairs and services in Raipur. We are committed to providing affordable, fast, and reliable services to make your life easier in Raipur city.",
      keywords: "About us, home service, Raipur, company info"
    },
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    meta: {
      title: "Terms and Conditions - Faster Home Service Raipur",
      description: "Read our terms and conditions for using Faster Home Service in Raipur. By booking services with us, you agree to our policies regarding payments, cancellations, and more.",
      keywords: "Terms and conditions, legal, home service, Raipur"
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: "Privacy Policy - Faster Home Service Raipur",
      description: "Our privacy policy outlines how we protect your personal information. Learn about our practices regarding data collection and usage for users in Raipur and beyond.",
      keywords: "Privacy policy, data protection, home service, Raipur"
    },
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage,
    meta: {
      title: "Contact Us - Faster Home Service Raipur",
      description: "Have any questions or need assistance with our services? Contact Faster Home Service in Raipur. Our customer support is ready to assist you with any inquiries you may have.",
      keywords: "Contact, customer service, home service, Raipur"
    },
  },
  {
    path: '/download-app',
    name: 'DownloadApp',
    component: DownloadApp,
    meta: {
      title: "Download Our App - Faster Home Service Raipur",
      description: "Download the Faster Home Service app today to book services in Raipur. Convenient, quick, and easy service booking from your smartphone.",
      keywords: "Download app, home service, Raipur, faster service"
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: "404 - Page Not Found",
      description: "The page you are looking for does not exist. Please check the URL or navigate to our homepage for the latest services available in Raipur.",
      keywords: "404, page not found, Raipur"
    },
  },
  {
    path: '/book',
    name: 'Booking',
    component: BookingPage,
    meta: {
      title: "Book a Service - Faster Home Service Raipur",
      description: "Book your home service appointment easily in Raipur with Faster Home Service. Our booking system lets you schedule repairs, installations, and more in just a few clicks.",
      keywords: "book service, home repair, Raipur, appointment"
    },
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

router.beforeEach((to, from, next) => {
  // Update the document title
  document.title = to.meta.title || "Faster Home Service"; // Default title

  // Set meta description
  const description = to.meta.description || "Default description for Faster Home Service";
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute('content', description);
  } else {
    const metaTag = document.createElement('meta');
    metaTag.setAttribute('name', 'description');
    metaTag.setAttribute('content', description);
    document.head.appendChild(metaTag);
  }

  // Set meta keywords
  const keywords = to.meta.keywords || "home service, repair, fast, booking";
  const metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute('content', keywords);
  } else {
    const metaTag = document.createElement('meta');
    metaTag.setAttribute('name', 'keywords');
    metaTag.setAttribute('content', keywords);
    document.head.appendChild(metaTag);
  }

  next();
});
