<template>
    <div class="booking-container">
      <!-- Hero Section -->
      <div class="hero-section">
        <h1>Book Your Appointment</h1>
        <p>Choose your preferred booking method and follow the instructions to reserve your spot.</p>
      </div>
  
      <!-- Booking Methods Section -->
      <div class="booking-methods">
        <!-- WhatsApp Booking -->
        <div class="booking-method">
          <a :href="whatsappLink" target="_blank" class="booking-btn whatsapp-btn">
            <i class="fab fa-whatsapp"></i> Book via WhatsApp
          </a>
        </div>
  
        <!-- Call Booking -->
        <div class="booking-method">
          <a :href="callLink" class="booking-btn call-btn">
            <i class="fas fa-phone-alt"></i> Call to Book
          </a>
        </div>
      </div>
  
      <!-- Booking Tips Section -->
      <div class="booking-tips">
        <h2>Booking Tips</h2>
        <ul>
          <li>For WhatsApp bookings, please include your full name, preferred time, and date in the message.</li>
          <li>If calling, please have your details ready to quickly complete the booking.</li>
          <li>Make sure to check availability before booking by chatting or calling.</li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        whatsappNumber: '+916263173842', // Replace with your WhatsApp number
        callNumber: '+916263173842', // Replace with your phone number
      };
    },
    computed: {
      whatsappLink() {
        return `https://wa.me/${this.whatsappNumber}`;
      },
      callLink() {
        return `tel:${this.callNumber}`;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Overall Booking Container */
  .booking-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, hsla(0, 2%, 12%, 0.702), hsla(0, 2%, 12%, 0.702)), url('@/assets/background.webp');
    background-size: cover;
    background-position: right;
    text-align: center;
    padding: 0 20px;
  }
  
  .hero-section {
    margin-bottom: 50px;
  }
  
  h1 {
    font-size: 3rem;
    margin-bottom: 10px;
    color: white;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
  
  p {
    font-size: 1.2rem;
    color: white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  }
  
  .booking-methods {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    animation: fadeInUp 1.5s ease-in-out;
  }
  
  .booking-method {
    position: relative;
    text-align: center;
  }
  
  .booking-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
    text-decoration: none;
    font-size: 1.2rem;
    border-radius: 50px;
    width: 250px;
    margin: 0 auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .booking-btn:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  }
  
  .whatsapp-btn {
    background-color: #25d366;
    color: white;
  }
  
  .call-btn {
    background-color: #34b7f1;
    color: white;
  }
  
  .whatsapp-btn i, .call-btn i {
    margin-right: 10px;
  }
  
  /* Tooltip */
  .tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    width: 200px;
    text-align: center;
    margin-top: 10px;
    animation: fadeInTooltip 0.3s ease;
  }
  
  /* Booking Tips Section */
  .booking-tips {
    margin-top: 40px;
    max-width: 600px;
    width: 100%;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .booking-tips h2 {
    margin-bottom: 20px;
  }
  
  .booking-tips ul {
    list-style-type: none;
    padding: 0;
  }
  
  .booking-tips li {
    margin-bottom: 10px;
    font-size: 1.1rem;
  }
  
  /* Keyframe Animations */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeUp {
    0% {
      transform: translateY(30px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeInUp {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeInTooltip {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .booking-container {
    height: auto;
    }
    .booking-btn {
      font-size: 1rem;
    }
    
    h1 {
      font-size: 2.5rem;
    }
  
    p {
      font-size: 1rem;
    }
  }
  </style>
  