<template>
     <div class="diamond-container">
     <div class="diamond-card">
       <div class="diamond-content">
         <h2>Appliance Repair Service</h2>
         <p>Is your appliance malfunctioning? Don't worry! We offer fast, reliable, and affordable repair services for all types of home appliances.</p>
         <a href="tel:+916263173842">
    <button class="callme">Call Me</button>
  </a>
  <a href="https://wa.me/+916263173842" target="_blank">
    <button class="chatme">Chat on WhatsApp</button>
  </a>
         <p class="extra-info">Our experts are just a call away to fix your appliance issues with 100% satisfaction guaranteed!</p>
       </div>
     </div>
   </div>
   <div class="service-bag">
    <div class="taital">Our Services</div>
    <div class="services-container">
      <router-link
        v-for="(service, index) in services"
        :key="index"
        :to="`/${service.route}`"
        class="service-card"
      >
        <div class="icon-container">
          <img :src="service.icon" alt="" />
        </div>
        <div class="text-container">
          <p>{{ service.name }}</p>
        </div>
      </router-link>
    </div>
  </div>
 
  <div class="service-flow-container">
   <h2 class="service-flow-title">Our Service Flow</h2>
   <div class="steps">
     <div class="step">
       <div class="step-icon">
         <i class="fas fa-calendar-check"></i>
       </div>
       <div class="step-details">
         <h3>Book a Service</h3>
         <p>Choose the service you need and schedule a convenient time for us to visit.</p>
       </div>
     </div>
     
     <div class="step">
       <div class="step-icon">
         <i class="fas fa-phone-alt"></i>
       </div>
       <div class="step-details">
         <h3>Confirmation Call</h3>
         <p>Our team will confirm the service details with a quick phone call.</p>
       </div>
     </div>
 
     <div class="step">
       <div class="step-icon">
         <i class="fas fa-tools"></i>
       </div>
       <div class="step-details">
         <h3>Service Execution</h3>
         <p>Our professionals will arrive and perform the service as per your requirements.</p>
       </div>
     </div>
 
     <div class="step">
       <div class="step-icon">
         <i class="fas fa-check-circle"></i>
       </div>
       <div class="step-details">
         <h3>Service Completion</h3>
         <p>Once the service is done, we’ll ensure everything is perfect before leaving.</p>
       </div>
     </div>
   </div>
 </div>

 <footer class="footer-container">
  <!-- Footer Content -->
  <div class="footer-content">
    <!-- About Section -->
    <div class="footer-section">
      <h3>About Us</h3>
      <p>We provide top-notch services to keep your appliances running smoothly. Quality and customer satisfaction are our priorities.</p>
    </div>

    <!-- Quick Links Section -->
    <div class="footer-section">
      <h3>Quick Links</h3>
      <ul>
        <li><router-link to="/about-us">About Us</router-link></li>
        <li><router-link to="/team">Team And Conditions</router-link></li>
        <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
        <li><router-link to="/contact">Contact</router-link></li>
        <li><router-link to="/download-app">Download App</router-link></li>
      </ul>
    </div>

    <!-- Contact Section -->
    <div class="footer-section">
      <h3>Contact Us</h3>
      <p>Phone: <a href="tel:6263173842">6263173842</a></p>
      <p>Email: <a href="mailto:support@example.com">support@example.com</a></p>
      <p>WhatsApp: <a href="https://wa.me/+916263173842" target="_blank">Chat on WhatsApp</a></p>
    </div>


    <!-- Social Media Section -->
    <div class="footer-section social-media">
      <h3>Follow Us</h3>
      <a href="https://www.facebook.com/share/18Eqm4CNWq/" class="social-icon" target="_blank">
        <i class="fab fa-facebook"></i> Facebook
      </a>
      <a href="https://x.com/fasterhomeservi?lang=en" class="social-icon" target="_blank">
        <i class="fab fa-twitter"></i> Twitter
      </a>
      <a href="https://www.instagram.com/fasterhomeservice?igsh=azVvcWRsMzF4cTNr" class="social-icon" target="_blank">
        <i class="fab fa-instagram"></i> Instagram
      </a>
      <a href="https://www.instagram.com/fasterhomeservice?igsh=azVvcWRsMzF4cTNr" class="social-icon" target="_blank">
        <i class="fab fa-linkedin"></i> LinkedIn
      </a>
    </div>
  </div>

  <!-- Footer Bottom Section -->
  <div class="footer-bottom">
    <p>&copy; 2024 Your Faster Home Service. All rights reserved.</p>
  </div>
</footer>



  </template>
  
  <script>
  export default {
    name: 'HomeScreenDesktop',
    data() {
     return {
        services: [
        { name: "AC Repair", icon: require("@/assets/images/service-icon/ac.png"), route: "ac-repair-service" },
        { name: "Washing Machine", icon: require("@/assets/images/service-icon/washing-machine.png"), route: "washing-machine-service" },
        { name: "Fridge Repair", icon: require("@/assets/images/service-icon/refrigretor.png"), route: "fridge-repair-service" },
        { name: "Ro Service", icon: require("@/assets/images/service-icon/ro.png"), route: "ro-service" },
        { name: "Geyser Repair", icon: require("@/assets/images/service-icon/geyser.png"), route: "geyser-repair-service" },
        { name: "Tv Install", icon: require("@/assets/images/service-icon/tv.png"), route: "tv-install-service" },
        { name: "Microwave Oven", icon: require("@/assets/images/service-icon/microwave.png"), route: "microwave-service" },
        { name: "Chimney Repair", icon: require("@/assets/images/service-icon/chimney.png"), route: "chimney-repair-service" },
        { name: "Sofa Cleaning", icon: require("@/assets/images/service-icon/sofa.png"), route: "sofa-cleaning-service" },
        { name: "Carpet Cleaning", icon: require("@/assets/images/service-icon/carpet.png"), route: "carpet-cleaning-service" },
        { name: "Mattess Cleaning", icon: require("@/assets/images/service-icon/mattess.png"), route: "mattress-cleaning-service" },
        { name: "Gas Stove", icon: require("@/assets/images/service-icon/gas.png"), route: "gas-stove-service" },
        { name: "Water Dispenser", icon: require("@/assets/images/service-icon/water-coor.png"), route: "water-dispenser-service" },
      ],
     };
   },
  };
  </script>
  
  <style scoped>
 .diamond-container {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100vh;
  position: relative;
  background-color: #f2f2f2;
  background-image: url('@/assets/images/heder.webp'); /* Replace with your image URL */
  background-position: center;
  background-size: cover; /* Ensures the image covers the entire area */
  overflow: hidden; /* Ensures overlay fits within the container */
}

.diamond-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay with 50% opacity */
  z-index: 1; /* Ensures the overlay is below the content */
}

.diamond-card {
  position: relative;
  max-width: 500px;
  z-index: 2; /* Ensures card content is above the overlay */
  margin-left: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.diamond-card h2 {
  font-size: 30px;
  margin: 0;
  color: hsl(0, 0%, 100%);
}

.diamond-card p {
  font-size: 16px;
  color: hwb(0 78% 21%);
  margin-top: 10px;
  line-height: 1.6;
}
.diamond-card .extra-info {
  font-size: 12px;
  color: hwb(0 78% 21%);
  margin-top: 20px;
}
.callme {
    background-color: #428a52;
    color: #f9f9f9;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 15px;
}
.chatme {
    background-color: #428a52;
    color: #f9f9f9;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 15px;
}

.taital {
  text-align: center; /* Centers the text */
  font-size: 30px; /* Adjust font size */
  font-weight: bold; /* Makes the text bold */
  color: hsl(0, 3%, 31%); /* Text color */
  position: relative; /* Required for the underline */
}

.taital::after {
  content: ''; /* Creates the pseudo-element */
  display: block;
  width: 25%; /* Adjust the width of the underline */
  height: 2px; /* Height of the underline */
  background-color: #393253; /* Color of the underline */
  margin: 10px auto 0; /* Centers the underline and adds spacing */
}

 .service-bag {
  background-color: hsl(0, 0%, 100%);
  border-radius: 24px;
  padding: 40px;
  position: relative;
  z-index: 2;
  justify-items: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
}

.services-container {
  display: grid; /* Switch to grid layout */
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive columns */
  gap: 20px; /* Space between grid items */
  padding: 20px;
}

.service-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.icon-container {
  margin-bottom: 10px;
}

.icon-container img {
  width: 70px;
  height: 70px;
}

.text-container p {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin: 0;
  font-family: 'Arial', sans-serif;
}
 </style>
 
 