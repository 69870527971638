<template>
    <div class="service-page">
      <div class="service-container">
        <!-- Left Side: Why Choose Us -->
      <WhyChooseUs />  
        <!-- Right Side: Service Details -->
        <div class="service-content-wrapper">
          <div class="service-image">
            <img src="@/assets/service/carpet/professional-carpet-cleaners.png" alt="AC Repair" />
          </div>
          <div class="service-content">
            <h2 class="service-title">Carpet Cleaning Service</h2>
            <p class="service-provider">
              <strong>Technician:</strong> ★ 4.9 (200 Reviews)
            </p>
            <div class="service-price"> Starting Price
              <span class="discount-price">12 /-</span>
              <span class="original-price">15 /-</span>
            </div>

            <div class="ac-service-row">
    <div class="ac-service-card">
      <div class="ac-service-image">
        <img src="@/assets/service/carpet/caepet.avif" alt="Carpet" />
      </div>
      <div class="ac-service-name">
        <h3>Carpet</h3>
      </div>
    </div>
  </div>
    
  <div class="top-service">
    <div v-for="(service, index) in services" :key="index" class="service-card">
      <img :src="service.image" :alt="service.name" class="service-image-detelas" />
      <div class="service-details">
        <h3 class="service-name">{{ service.name }}</h3>
        <div class="service-price">
          <span class="discount-price">₹{{ service.discountPrice }}</span>
          <span class="price-name">{{ service.priceUnit }}</span>
        </div>
        <router-link to="/book">
          <button class="book-button">Book Now</button>
        </router-link>
      </div>
    </div>
  </div>


          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import WhyChooseUs from "@/components/WhyChooseUs.vue";
  export default {
    name: "CarpetCleaningService",
    components: {
    WhyChooseUs,
  },
    data() {
    return {
      services: [
        {
          name: "Carpet Cleaning ( 25 SQFT )",
          discountPrice: 300,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/carpet/cae.jpeg"),
        },
        {
          name: "Carpet Cleaning (26-50 SQFT)",
          discountPrice: 600,
          priceUnit: "(Par Seat)",
          image: require("@/assets/service/carpet/car.jpeg"),
        },
        {
          name: "Carpet Cleaning",
          discountPrice: 12,
          priceUnit: "(Par SQFT)",
          image: require("@/assets/service/carpet/carpert.jpeg"),
        },
      ],
    };
  },
  };
  </script>