<template>
    <div class="specialization-section">
      <div class="additional-features">
        <h3>Why Choose Us?</h3>
        <div 
          class="feature-item" 
          v-for="(feature, index) in features" 
          :key="index"
        >
          <h4>
            <img :src="feature.icon" :alt="feature.alt" /> 
            {{ feature.title }}
          </h4>
          <p>{{ feature.description }}</p>
        </div>
      </div>
    </div>
  </template>
<script>
export default {
  name: "WhyChooseUs",
  data() {
    return {
      features: [
        {
          icon: require("@/assets/images/icon/Satisfaction-Guarantee.png"),
          alt: "Satisfaction Guarantee Icon",
          title: "100% Satisfaction",
          description: "We don’t provide service only but we change the perception and feeling along. If not, free rework is done.",
        },
        {
          icon: require("@/assets/images/icon/Icons_stop_blau_new.svg"),
          alt: "One Stop Solution Icon",
          title: "One Stop Solution",
          description: "We provide 110+ Services at the best price so that customers need not go outside of tashome.in for further choice.",
        },
        {
          icon: require("@/assets/images/icon/quality-assurance-17.png"),
          alt: "Quality Assurance Icon",
          title: "Quality Assurance",
          description: "We don’t tell our standards; we show them. The work is performed only by our specialist champs.",
        },
        {
          icon: require("@/assets/images/icon/eye-icon-png-viewed-accomms-10.png"),
          alt: "Transparency Icon",
          title: "Transparency",
          description: "At Faster Service, we put clear information about the service at every stage of operation in front of customers.",
        },
      ],
    };
  },
};
</script>

  