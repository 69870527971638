<template>
    <div v-if="isVisible" class="loader-overlay">
      <img src="@/assets/loader.gif" alt="Loading..." class="loader-image" />
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
  };
  </script>
  
  <style scoped>
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FCFCFF;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loader-image {
    width: 250px;
  }
  @media (max-width: 768px) {
    .loader-image {
    width: 200px;
  }
}
  </style>
  