<template>
    <div class="download-app">
      <p>Redirecting to the app download page...</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "DownloadApp",
    mounted() {
      // Replace with your actual app download URL
      const appDownloadUrl = "https://play.google.com/store/apps/details?id=com.wnapp.id1714472317982&pcampaignid=web_share";
  
      // Redirect the user
      window.location.href = appDownloadUrl;
    },
  };
  </script>
  
  <style scoped>
  .download-app {
    text-align: center;
    margin-top: 50px;
    font-family: Arial, sans-serif;
    font-size: 18px;
    color: #333;
  }
  </style>
  