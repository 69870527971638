<template>
    <div class="service-page">
      <div class="service-container">
      <!-- Left Side: Why Choose Us -->
      <WhyChooseUs /> 
        <!-- Right Side: Service Details -->
        <div class="service-content-wrapper">
          <div class="service-image">
            <img src="@/assets/service/frigde/6645262.webp" alt="AC Repair" />
          </div>
          <div class="service-content">
            <h2 class="service-title">Fridge Repair Service (Refrigerator)</h2>
            <p class="service-provider">
              <strong>Technician:</strong> ★ 4.9 (200 Reviews)
            </p>
            <div class="service-price"> Starting Price
              <span class="discount-price">299 /-</span>
              <span class="original-price">450 /-</span>
            </div>

            <div class="ac-service-row">
    <div class="ac-service-card">
      <div class="ac-service-image">
        <img src="@/assets/service/frigde/singal door fridge.png" alt="singal door fridge" />
      </div>
      <div class="ac-service-name">
        <h3>Singal Door</h3>
      </div>
    </div>
    <div class="ac-service-card">
      <div class="ac-service-image">
        <img src="@/assets/service/frigde/dobal door frigde.png" alt="double door frigde" />
      </div>
      <div class="ac-service-name">
        <h3>Double Door</h3>
      </div>
    </div>
    <div class="ac-service-card">
      <div class="ac-service-image">
        <img src="@/assets/service/frigde/side by side frigde.png" alt="side by side frigde" />
      </div>
      <div class="ac-service-name">
        <h3>Side By Side Door</h3>
      </div>
    </div>
  </div>
    
  <div class="top-service">
    <div v-for="(service, index) in services" :key="index" class="service-card">
      <img :src="service.image" :alt="service.name" class="service-image-detelas" />
      <div class="service-details">
        <h3 class="service-name">{{ service.name }}</h3>
        <div class="service-price">
          <span class="discount-price">₹{{ service.discountPrice }}</span>
          <span class="price-name">{{ service.priceUnit }}</span>
        </div>
        <router-link to="/book">
          <button class="book-button">Book Now</button>
        </router-link>
      </div>
    </div>
  </div>


          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import WhyChooseUs from "@/components/WhyChooseUs.vue";
  export default {
    name: "FridgeRepairService",
    components: {
    WhyChooseUs,
  },
    data() {
    return {
      services: [
        {
          name: "Repair And Cheking Charge (Singal door , Double Door)",
          discountPrice: 299,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/frigde/prod-image-500x500.webp"),
        },
        {
          name: "Side By Side Frigde Repair And Cheking Charge",
          discountPrice: 349,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/frigde/repair side by side.webp"),
        },
        {
          name: "Deep Fridge Repair And Cheking",
          discountPrice: 300,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/frigde/deep fride.webp"),
        },
        {
          name: "Pepsi Refrigerator Repair And Cheking",
          discountPrice: 350,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/frigde/pepsi frigde.jpg"),
        },
        {
          name: "Commercial Refrigerator",
          discountPrice: 350,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/frigde/commsiyal fride.webp"),
        },
      ],
    };
  },
  };
  </script>