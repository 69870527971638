<template>
    <div class="service-page">
      <div class="service-container">
        <!-- Left Side: Why Choose Us -->
      <WhyChooseUs />
        <div class="service-content-wrapper">
          <div class="service-image">
            <img src="@/assets/service/water/water taital.png" alt="AC Repair" />
          </div>
          <div class="service-content">
            <h2 class="service-title">Water Dispenser Service</h2>
            <p class="service-provider">
              <strong>Technician:</strong> ★ 4.9 (200 Reviews)
            </p>
            <div class="service-price"> Starting Price
              <span class="discount-price">249 /-</span>
              <span class="original-price">300 /-</span>
            </div>

            <div class="ac-service-row">
    <div class="ac-service-card">
      <div class="ac-service-image">
        <img src="@/assets/service/water/Voltas-minimagic-super-t.png" alt="Water Dispenser" />
      </div>
      <div class="ac-service-name">
        <h3>Water Dispenser</h3>
      </div>
    </div>
  </div>
    
  <div class="top-service">
    <div v-for="(service, index) in services" :key="index" class="service-card">
      <img :src="service.image" :alt="service.name" class="service-image-detelas" />
      <div class="service-details">
        <h3 class="service-name">{{ service.name }}</h3>
        <div class="service-price">
          <span class="discount-price">₹{{ service.discountPrice }}</span>
          <span class="price-name">{{ service.priceUnit }}</span>
        </div>
        <router-link to="/book">
          <button class="book-button">Book Now</button>
        </router-link>
      </div>
    </div>
  </div>


          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
import WhyChooseUs from "@/components/WhyChooseUs.vue";

export default {
  name: "WaterDispenserService",
  components: {
    WhyChooseUs,
  },
  data() {
    return {
      services: [
        {
          name: "Water Dispenser Repair And Checking",
          discountPrice: 250,
          priceUnit: "(Per Piece)", // Fixed typo: "Par" -> "Per"
          image: require("@/assets/service/water/20160816_112342_water_filter-1024x768.jpg"),
        },
        {
          name: "Water Dispenser Cleaning",
          discountPrice: 300,
          priceUnit: "(Per Piece)", // Fixed typo: "Par" -> "Per"
          image: require("@/assets/service/water/External-Cleaning-Dispenser-269x300.jpg"),
        },
      ],
    };
  },
};
</script>