<template>
    <div class="not-found-container">
      <div class="content">
        <div class="image-container">
        <img src="@/assets/404-pages.webp" alt="404 Not Found" class="not-found-image" />
      </div>
        <h1>Page Not Found</h1>
        <p>The page you are looking for does not exist.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound'
  }
  </script>
  
  <style scoped>
  .not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh; /* Full viewport height */
    text-align: center;
  }
  
  .content {
    margin-bottom: 20px;
  }
  
  h1 {
    color: #5f5f5f;
    font-size: 3rem;
    margin-top: -30px;
  }
  
  .not-found-image {
    max-width: 100%;
    height: auto;
    max-width: 500px; /* Set a max-width to prevent the image from growing too large */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    h1 {
      font-size: 2rem; /* Smaller text on smaller screens */
    }
  
    .not-found-image {
      max-width: 80%; /* Image becomes smaller on smaller screens */
    }
  }
  </style>
  