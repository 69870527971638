<template>
  <div class="home-mobile">
    <div class="image-container">
      <img :src="headerImage" alt="Header Image" class="home-img">
    </div>

    <div class="service-main">
      <div class="service-container">
        <!-- Loop through the serviceList array and render each service item -->
        <router-link v-for="service in serviceList" :key="service.name" :to="service.link">
          <div class="service-item">
            <img :src="service.icon" :alt="service.name">
            <p>{{ service.name }}</p>
          </div>
        </router-link>
      </div>
    </div>

    <div class="top-service" style="padding: 15px;">
      <div v-for="(service, index) in topServiceList" :key="index" class="service-card">
        <img :src="service.image" :alt="service.name" class="service-image-detelas">
        <div class="service-details">
          <h3 class="service-name">{{ service.name }}</h3>
          <div class="service-price">
            <span class="discount-price">{{ service.discountPrice }}</span>
            <span class="original-price">{{ service.originalPrice }}</span>
            <span class="price-name">({{ service.priceType }})</span>
          </div>
          <router-link to="/book">
            <button class="book-button">Book Now</button>
          </router-link>
        </div>
      </div>
    </div>

    <div class="info-links">
      <div class="main-links">
        <router-link to="/about-us">About Us</router-link>
        <router-link to="/team">Team And Conditions</router-link>
        <router-link to="/privacy-policy">Privacy Policy</router-link>
        <router-link to="/contact">Contact</router-link>
        <router-link to="/download-app">Download App</router-link>
      </div>

      <div class="social-media-app">
        <a href="https://www.facebook.com/share/18Eqm4CNWq/" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <img :src="facebookIcon" alt="Facebook">
        </a>
        <a href="https://www.instagram.com/fasterhomeservice?igsh=azVvcWRsMzF4cTNr" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
          <img :src="instagramIcon" alt="Instagram">
        </a>
        <a href="https://wa.me/916263173842" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
          <img :src="whatsappIcon" alt="WhatsApp">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeScreenMobile',
  data() {
    return {
      // Static image paths
      headerImage: require('@/assets/images/heder.webp'),
      facebookIcon: require('@/assets/images/icon/facebook-on.png'),
      instagramIcon: require('@/assets/images/icon/instagram.png'),
      whatsappIcon: require('@/assets/images/icon/whatsapp.png'),

      // Service list for the main page
      serviceList: [
        { name: 'AC Repair', icon: require('@/assets/images/mobicon/ac.png'), link: '/ac-repair-service' },
        { name: 'Washing Machine', icon: require('@/assets/images/mobicon/washing-machine.png'), link: '/washing-machine-service' },
        { name: 'RO Repair', icon: require('@/assets/images/mobicon/ro.png'), link: '/ro-service' },
        { name: 'Fridge Repair', icon: require('@/assets/images/mobicon/refrigerator.png'), link: '/fridge-repair-service' },
        { name: 'Geyser Repair', icon: require('@/assets/images/mobicon/geyser.png'), link: '/geyser-repair-service' },
        { name: 'Television Install', icon: require('@/assets/images/mobicon/tv.png'), link: '/tv-install-service' },
        { name: 'Microwave Oven', icon: require('@/assets/images/mobicon/microwave-oven.png'), link: '/microwave-service' },
        { name: 'Chimney Service', icon: require('@/assets/images/mobicon/chimney.png'), link: '/chimney-repair-service' },
        { name: 'Sofa Cleaning', icon: require('@/assets/images/mobicon/sofa.png'), link: '/sofa-cleaning-service' },
        { name: 'Carpet Cleaning', icon: require('@/assets/images/mobicon/carpet.png'), link: '/carpet-cleaning-service' },
        { name: 'Mattress Cleaning', icon: require('@/assets/images/mobicon/mattess.png'), link: '/mattress-cleaning-service' },
        { name: 'Gas Stove', icon: require('@/assets/images/mobicon/gas-stove.png'), link: '/gas-stove-service' },
      ],

      // Top services section list
      topServiceList: [
        {
          name: 'AC Jet Cleaning ( Window , Split )',
          image: require('@/assets/images/services/ac jet.webp'),
          discountPrice: '₹450',
          originalPrice: '₹799',
          priceType: 'Per Piece',
        },
        {
          name: 'Sofa Cleaning( Fabric )',
          image: require('@/assets/images/services/sofa.webp'),
          discountPrice: '₹160',
          originalPrice: '₹210',
          priceType: 'Per Seat',
        },
        {
          name: 'Chimney Cleaning ( Narmal )',
          image: require('@/assets/images/services/chimney.webp'),
          discountPrice: '₹800',
          originalPrice: '₹1200',
          priceType: 'Per Piece',
        },
      ],
    };
  },
};
</script>

  <style scoped>
  .image-container {
  max-width: 100%; /* Prevents overflow of the parent container */
  padding: 14px; /* Adds spacing inside the container */
}
.home-img{
  width: 100%;
  border-radius: 5px; /* Rounds the corners of the border */
}

.service-main {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; 
    margin-top: 15px;
}
.service-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 items per row */
    gap: 20px; /* Space between items */
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    max-width: 1000px; /* Optional: Set a max-width for the container */
    margin: 0 auto;
    grid-auto-rows: 1fr;
}

/* Service Item */
.service-item {
  display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribute space between items inside */
    align-items: center; /* Center-align the content */
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 70%;
    width: 70%;
}

.service-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.service-item img {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
}

.service-item p {
  font-size: 12px;
  font-weight: 500;
  color: #333;
  text-align: center;
  margin: 0;
  display: -webkit-box; /* Use this */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  box-orient: vertical;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

@media screen and (max-width: 450px) {
    .service-container {
        grid-template-columns: repeat(3, 1fr); /* 4 items per row */
        gap: 10px; /* Space between items */
    }
    .service-item img {
    width: 30px;
    height: 30px;
}
  }


.info-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  max-width: 400px;
  margin: 0 auto; /* Center align on the page */
}

.main-links {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
  text-align: center; /* Center-aligns text */
}

.main-links a {
  text-decoration: none;
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  transition: color 0.3s ease;
}

.main-links a:hover {
  color: #007bff; /* Change color on hover */
}

.social-media-app {
  display: flex;
  justify-content: space-between; /* Ensures equal space between items */
  gap: 30px; /* Adjusts spacing between icons */
  align-items: center;
}

.social-media a {
  display: inline-block;
  width: 30px;
  height: 30px;
}

.social-media-app img {
  width: 18px;
  height: 18px;
  padding: 10px;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 10px;
  object-fit: contain; /* Ensures the image fits within the box */
  transition: transform 0.3s ease;
}

.social-media img:hover {
  transform: scale(1.1); /* Slightly enlarges icon on hover */
}
  </style>
  