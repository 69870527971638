<template>
  <div>
    <!-- Loader Component -->
    <Loader :isVisible="isLoading" />

    <!-- Render Current Page -->
    <div v-if="!isLoading">
      <component :is="currentPage" />
    </div>
  </div>
</template>

<script>
import MobilePage from "@/components/MobilePage.vue";
import DesktopPage from "@/components/DesktopPage.vue";
import Loader from "@/components/AppLoader.vue";

export default {
  name: "App",
  components: {
    MobilePage,
    DesktopPage,
    Loader,
  },
  data() {
    return {
      isMobile: false, // Tracks device type
      isLoading: true, // Controls loader visibility
    };
  },
  computed: {
    currentPage() {
      return this.isMobile ? "MobilePage" : "DesktopPage";
    },
  },
  methods: {
    checkDevice() {
      this.isMobile = window.innerWidth <= 768; // Adjust for mobile width
    },
    simulateLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 1500); // Simulates page load delay (1.5 seconds)
    },
  },
  mounted() {
    document.title = "Faster Home Service";
    this.checkDevice(); // Check device type on mount
    this.simulateLoading(); // Start with a simulated load
    window.addEventListener("resize", this.checkDevice); // Update on resize
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkDevice); // Cleanup listeners
  },
};
</script>

<style>
/* Global Styles */
body {
  font-family: DM Sans, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: normal;
  margin: 0;
  padding: 0;
  color: #324253;
  padding-top: 60px;
  padding-bottom: 0;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  body {
    padding-bottom: 70px;
  }
}
</style>
