<template>
    <div class="contact-page">
      <h1>Contact Us</h1>
      <p class="contact-intro">
        We’d love to hear from you! Reach out to us through the details below.
      </p>
  
      <div class="contact-container">
        <!-- Contact Information -->
        <div class="contact-info">
          <h2>Get in Touch</h2>
          <p><strong>Company Name:</strong> Faster Home Service</p>
          <p><strong>Email:</strong> support@fasterhomeservice.com</p>
          <p><strong>Phone:</strong> +91-6263173842</p>
          <p><strong>Address:</strong> Shop No. 15 Sahu Complex Mathpurena Shantoshi Nagar Raipur</p>
          <div class="social-icons">
            <a href="https://www.facebook.com/share/18Eqm4CNWq/" target="_blank">
              <img src="@/assets/images/icon/facebook.png" alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/fasterhomeservice?igsh=azVvcWRsMzF4cTNr" target="_blank">
              <img src="@/assets/images/icon/instagram.png" alt="Twitter" />
            </a>
            <a href="https://wa.me/916263173842" target="_blank">
              <img src="@/assets/images/icon/whatsapp.png" alt="Instagram" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </template>
<script>
export default {
  name: "ContactPage",
};
</script>
<style scoped>
.contact-page {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: hsl(240, 1%, 31%);
}

.contact-intro {
  text-align: center;
  margin-bottom: 40px;
  font-size: 18px;
}

.contact-container {
  display: flex;
  justify-content: center;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
}

.contact-info {
  text-align: center;
}

.contact-info h2 {
  margin-bottom: 15px;
  color: hsl(240, 1%, 31%);
}

.contact-info p {
  margin: 10px 0;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.social-icons img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
</style>
  