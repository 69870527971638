<template>
    <div class="service-page">
      <div class="service-container">
     <!-- Left Side: Why Choose Us -->
     <WhyChooseUs /> 
        <!-- Right Side: Service Details -->
        <div class="service-content-wrapper">
          <div class="service-image">
            <img src="@/assets/service/sofa/safa cleaninf taital.webp" alt="AC Repair" />
          </div>
          <div class="service-content">
            <h2 class="service-title">Sofa Cleaning Service</h2>
            <p class="service-provider">
              <strong>Technician:</strong> ★ 4.9 (200 Reviews)
            </p>
            <div class="service-price"> Starting Price
              <span class="discount-price">49 /-</span>
              <span class="original-price">350 /-</span>
            </div>

            <div class="ac-service-row">
    <div class="ac-service-card">
      <div class="ac-service-image">
        <img src="@/assets/service/sofa/fabric sofa.png" alt="Fabric Sofa" />
      </div>
      <div class="ac-service-name">
        <h3>Fabric Sofa</h3>
      </div>
    </div>
    <div class="ac-service-card">
      <div class="ac-service-image">
        <img src="@/assets/service/sofa/leder sofa.png" alt="Sofa Leder" />
      </div>
      <div class="ac-service-name">
        <h3>Sofa Leder</h3>
      </div>
    </div>
  </div>
    
  <div class="top-service">
    <div v-for="(service, index) in services" :key="index" class="service-card">
      <img :src="service.image" :alt="service.name" class="service-image-detelas" />
      <div class="service-details">
        <h3 class="service-name">{{ service.name }}</h3>
        <div class="service-price">
          <span class="discount-price">₹{{ service.discountPrice }}</span>
          <span class="price-name">{{ service.priceUnit }}</span>
        </div>
        <router-link to="/book">
          <button class="book-button">Book Now</button>
        </router-link>
      </div>
    </div>
  </div>


          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import WhyChooseUs from "@/components/WhyChooseUs.vue";
  export default {
    name: "SofaCleaningService",
    components: {
    WhyChooseUs,
  },
    data() {
    return {
      services: [
        {
          name: "Fabric Sofa Cleaning",
          discountPrice: 179,
          priceUnit: "(Par Seat)",
          image: require("@/assets/service/sofa/fabric.jpeg"),
        },
        {
          name: "Leather Sofa",
          discountPrice: 140,
          priceUnit: "(Par Seat)",
          image: require("@/assets/service/sofa/leathear.webp"),
        },
        {
          name: "Cushion Cleaning",
          discountPrice: 49,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/sofa/cushion.jpeg"),
        },
        {
          name: "Couch Sofa",
          discountPrice: 300,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/sofa/coch.jpeg"),
        },
        {
          name: "Dining Chair",
          discountPrice: 99,
          priceUnit: "(Par Piece)",
          image: require("@/assets/service/sofa/diniging.jpeg"),
        },
      ],
    };
  },
  };
  </script>