<template>
    <!-- Navigation Bar -->
    <header class="header">
      <img :src="require('@/assets/images/logo.webp')" alt="Logo">
        <div class="location">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3 15.9995C3 16.5299 3.21071 17.0387 3.58579 17.4137C3.96086 17.7888 4.46957 17.9995 5 17.9995H19C19.5304 17.9995 20.0391 17.7888 20.4142 17.4137C20.7893 17.0387 21 16.5299 21 15.9995V13.4995H3V15.9995Z"
                                            fill="#138808" />
                                        <path
                                            d="M21 9.5V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7L3 9.5H21Z"
                                            fill="#FF9933" />
                                        <path d="M3 9.3335H21V13.667H3V9.3335Z" fill="#F7F7F7" />
                                        <path
                                            d="M11.9995 13.5C13.1041 13.5 13.9995 12.6046 13.9995 11.5C13.9995 10.3954 13.1041 9.5 11.9995 9.5C10.8949 9.5 9.99951 10.3954 9.99951 11.5C9.99951 12.6046 10.8949 13.5 11.9995 13.5Z"
                                            fill="#000080" />
                                        <path
                                            d="M12 13.188C12.932 13.188 13.6875 12.4325 13.6875 11.5005C13.6875 10.5685 12.932 9.81299 12 9.81299C11.068 9.81299 10.3125 10.5685 10.3125 11.5005C10.3125 12.4325 11.068 13.188 12 13.188Z"
                                            fill="#F7F7F7" />
                                        <path
                                            d="M12.0499 10.8754L11.9999 11.2004L11.9499 10.8754L11.9999 9.90039L12.0499 10.8754ZM11.5859 9.95489L11.7899 10.9094L11.9224 11.2104L11.8864 10.8839L11.5859 9.95489ZM11.1999 10.1149L11.6439 10.9839L11.8499 11.2404L11.7309 10.9339L11.1999 10.1149ZM10.8684 10.3689L11.5224 11.0939L11.7879 11.2884L11.5934 11.0229L10.8684 10.3689ZM10.6144 10.7004L11.4334 11.2314L11.7399 11.3504L11.4834 11.1444L10.6144 10.7004ZM10.4544 11.0864L11.3834 11.3869L11.7099 11.4229L11.4089 11.2904L10.4544 11.0864ZM10.3999 11.5004L11.3749 11.5504L11.6999 11.5004L11.3749 11.4504L10.3999 11.5004ZM10.4544 11.9144L11.4089 11.7104L11.7099 11.5779L11.3834 11.6139L10.4544 11.9144ZM10.6144 12.3004L11.4834 11.8564L11.7399 11.6504L11.4334 11.7694L10.6144 12.3004ZM10.8684 12.6319L11.5934 11.9779L11.7879 11.7124L11.5224 11.9069L10.8684 12.6319ZM11.1999 12.8859L11.7309 12.0669L11.8499 11.7604L11.6439 12.0169L11.1999 12.8859ZM11.5859 13.0459L11.8864 12.1169L11.9224 11.7904L11.7899 12.0914L11.5859 13.0459ZM11.9999 13.1004L12.0499 12.1254L11.9999 11.8004L11.9499 12.1254L11.9999 13.1004ZM12.4139 13.0459L12.2099 12.0914L12.0774 11.7904L12.1134 12.1169L12.4139 13.0459ZM12.7999 12.8859L12.3559 12.0169L12.1499 11.7604L12.2689 12.0669L12.7999 12.8859ZM13.1314 12.6319L12.4774 11.9069L12.2119 11.7124L12.4064 11.9779L13.1314 12.6319ZM13.3854 12.3004L12.5664 11.7694L12.2599 11.6504L12.5164 11.8564L13.3854 12.3004ZM13.5454 11.9144L12.6164 11.6139L12.2899 11.5779L12.5909 11.7104L13.5454 11.9144ZM13.5999 11.5004L12.6249 11.4504L12.2999 11.5004L12.6249 11.5504L13.5999 11.5004ZM13.5454 11.0864L12.5909 11.2904L12.2899 11.4229L12.6164 11.3869L13.5454 11.0864ZM13.3854 10.7004L12.5164 11.1444L12.2599 11.3504L12.5664 11.2314L13.3854 10.7004ZM13.1314 10.3689L12.4064 11.0229L12.2119 11.2884L12.4774 11.0939L13.1314 10.3689ZM12.7999 10.1149L12.2689 10.9339L12.1499 11.2404L12.3559 10.9839L12.7999 10.1149ZM12.4139 9.95489L12.1134 10.8839L12.0774 11.2104L12.2099 10.9094L12.4139 9.95489Z"
                                            fill="#6666B3" />
                                        <path
                                            d="M11.7802 9.929C11.8354 9.929 11.8802 9.88423 11.8802 9.829C11.8802 9.77378 11.8354 9.729 11.7802 9.729C11.7249 9.729 11.6802 9.77378 11.6802 9.829C11.6802 9.88423 11.7249 9.929 11.7802 9.929Z"
                                            fill="#000080" />
                                        <path
                                            d="M11.3549 10.0433C11.4101 10.0433 11.4549 9.99849 11.4549 9.94326C11.4549 9.88803 11.4101 9.84326 11.3549 9.84326C11.2997 9.84326 11.2549 9.88803 11.2549 9.94326C11.2549 9.99849 11.2997 10.0433 11.3549 10.0433Z"
                                            fill="#000080" />
                                        <path
                                            d="M10.9745 10.263C11.0297 10.263 11.0745 10.2182 11.0745 10.163C11.0745 10.1078 11.0297 10.063 10.9745 10.063C10.9193 10.063 10.8745 10.1078 10.8745 10.163C10.8745 10.2182 10.9193 10.263 10.9745 10.263Z"
                                            fill="#000080" />
                                        <path
                                            d="M10.6625 10.5735C10.7177 10.5735 10.7625 10.5288 10.7625 10.4735C10.7625 10.4183 10.7177 10.3735 10.6625 10.3735C10.6073 10.3735 10.5625 10.4183 10.5625 10.4735C10.5625 10.5288 10.6073 10.5735 10.6625 10.5735Z"
                                            fill="#000080" />
                                        <path
                                            d="M10.4442 10.9549C10.4995 10.9549 10.5442 10.9101 10.5442 10.8549C10.5442 10.7997 10.4995 10.7549 10.4442 10.7549C10.389 10.7549 10.3442 10.7997 10.3442 10.8549C10.3442 10.9101 10.389 10.9549 10.4442 10.9549Z"
                                            fill="#000080" />
                                        <path
                                            d="M10.33 11.3797C10.3852 11.3797 10.43 11.3349 10.43 11.2797C10.43 11.2245 10.3852 11.1797 10.33 11.1797C10.2748 11.1797 10.23 11.2245 10.23 11.2797C10.23 11.3349 10.2748 11.3797 10.33 11.3797Z"
                                            fill="#000080" />
                                        <path
                                            d="M10.33 11.8196C10.3852 11.8196 10.43 11.7749 10.43 11.7196C10.43 11.6644 10.3852 11.6196 10.33 11.6196C10.2748 11.6196 10.23 11.6644 10.23 11.7196C10.23 11.7749 10.2748 11.8196 10.33 11.8196Z"
                                            fill="#000080" />
                                        <path
                                            d="M10.4442 12.2454C10.4995 12.2454 10.5442 12.2006 10.5442 12.1454C10.5442 12.0902 10.4995 12.0454 10.4442 12.0454C10.389 12.0454 10.3442 12.0902 10.3442 12.1454C10.3442 12.2006 10.389 12.2454 10.4442 12.2454Z"
                                            fill="#000080" />
                                        <path
                                            d="M10.6625 12.6258C10.7177 12.6258 10.7625 12.581 10.7625 12.5258C10.7625 12.4706 10.7177 12.4258 10.6625 12.4258C10.6073 12.4258 10.5625 12.4706 10.5625 12.5258C10.5625 12.581 10.6073 12.6258 10.6625 12.6258Z"
                                            fill="#000080" />
                                        <path
                                            d="M10.9745 12.9368C11.0297 12.9368 11.0745 12.892 11.0745 12.8368C11.0745 12.7816 11.0297 12.7368 10.9745 12.7368C10.9193 12.7368 10.8745 12.7816 10.8745 12.8368C10.8745 12.892 10.9193 12.9368 10.9745 12.9368Z"
                                            fill="#000080" />
                                        <path
                                            d="M11.3549 13.1561C11.4101 13.1561 11.4549 13.1113 11.4549 13.0561C11.4549 13.0008 11.4101 12.9561 11.3549 12.9561C11.2997 12.9561 11.2549 13.0008 11.2549 13.0561C11.2549 13.1113 11.2997 13.1561 11.3549 13.1561Z"
                                            fill="#000080" />
                                        <path
                                            d="M11.7802 13.2698C11.8354 13.2698 11.8802 13.2251 11.8802 13.1698C11.8802 13.1146 11.8354 13.0698 11.7802 13.0698C11.7249 13.0698 11.6802 13.1146 11.6802 13.1698C11.6802 13.2251 11.7249 13.2698 11.7802 13.2698Z"
                                            fill="#000080" />
                                        <path
                                            d="M12.2201 13.2698C12.2753 13.2698 12.3201 13.2251 12.3201 13.1698C12.3201 13.1146 12.2753 13.0698 12.2201 13.0698C12.1649 13.0698 12.1201 13.1146 12.1201 13.1698C12.1201 13.2251 12.1649 13.2698 12.2201 13.2698Z"
                                            fill="#000080" />
                                        <path
                                            d="M12.6444 13.1561C12.6997 13.1561 12.7444 13.1113 12.7444 13.0561C12.7444 13.0008 12.6997 12.9561 12.6444 12.9561C12.5892 12.9561 12.5444 13.0008 12.5444 13.0561C12.5444 13.1113 12.5892 13.1561 12.6444 13.1561Z"
                                            fill="#000080" />
                                        <path
                                            d="M13.0253 12.9363C13.0805 12.9363 13.1253 12.8916 13.1253 12.8363C13.1253 12.7811 13.0805 12.7363 13.0253 12.7363C12.9701 12.7363 12.9253 12.7811 12.9253 12.8363C12.9253 12.8916 12.9701 12.9363 13.0253 12.9363Z"
                                            fill="#000080" />
                                        <path
                                            d="M13.3373 12.6258C13.3925 12.6258 13.4373 12.581 13.4373 12.5258C13.4373 12.4706 13.3925 12.4258 13.3373 12.4258C13.2821 12.4258 13.2373 12.4706 13.2373 12.5258C13.2373 12.581 13.2821 12.6258 13.3373 12.6258Z"
                                            fill="#000080" />
                                        <path
                                            d="M13.5565 12.2454C13.6118 12.2454 13.6565 12.2006 13.6565 12.1454C13.6565 12.0902 13.6118 12.0454 13.5565 12.0454C13.5013 12.0454 13.4565 12.0902 13.4565 12.1454C13.4565 12.2006 13.5013 12.2454 13.5565 12.2454Z"
                                            fill="#000080" />
                                        <path
                                            d="M13.6708 11.8196C13.726 11.8196 13.7708 11.7749 13.7708 11.7196C13.7708 11.6644 13.726 11.6196 13.6708 11.6196C13.6156 11.6196 13.5708 11.6644 13.5708 11.7196C13.5708 11.7749 13.6156 11.8196 13.6708 11.8196Z"
                                            fill="#000080" />
                                        <path
                                            d="M13.6708 11.3797C13.726 11.3797 13.7708 11.3349 13.7708 11.2797C13.7708 11.2245 13.726 11.1797 13.6708 11.1797C13.6156 11.1797 13.5708 11.2245 13.5708 11.2797C13.5708 11.3349 13.6156 11.3797 13.6708 11.3797Z"
                                            fill="#000080" />
                                        <path
                                            d="M13.5565 10.9549C13.6118 10.9549 13.6565 10.9101 13.6565 10.8549C13.6565 10.7997 13.6118 10.7549 13.5565 10.7549C13.5013 10.7549 13.4565 10.7997 13.4565 10.8549C13.4565 10.9101 13.5013 10.9549 13.5565 10.9549Z"
                                            fill="#000080" />
                                        <path
                                            d="M13.3373 10.5735C13.3925 10.5735 13.4373 10.5288 13.4373 10.4735C13.4373 10.4183 13.3925 10.3735 13.3373 10.3735C13.2821 10.3735 13.2373 10.4183 13.2373 10.4735C13.2373 10.5288 13.2821 10.5735 13.3373 10.5735Z"
                                            fill="#000080" />
                                        <path
                                            d="M13.0253 10.263C13.0805 10.263 13.1253 10.2182 13.1253 10.163C13.1253 10.1078 13.0805 10.063 13.0253 10.063C12.9701 10.063 12.9253 10.1078 12.9253 10.163C12.9253 10.2182 12.9701 10.263 13.0253 10.263Z"
                                            fill="#000080" />
                                        <path
                                            d="M12.6444 10.0433C12.6997 10.0433 12.7444 9.99849 12.7444 9.94326C12.7444 9.88803 12.6997 9.84326 12.6444 9.84326C12.5892 9.84326 12.5444 9.88803 12.5444 9.94326C12.5444 9.99849 12.5892 10.0433 12.6444 10.0433Z"
                                            fill="#000080" />
                                        <path
                                            d="M12.2201 9.929C12.2753 9.929 12.3201 9.88423 12.3201 9.829C12.3201 9.77378 12.2753 9.729 12.2201 9.729C12.1649 9.729 12.1201 9.77378 12.1201 9.829C12.1201 9.88423 12.1649 9.929 12.2201 9.929Z"
                                            fill="#000080" />
                                        <path
                                            d="M11.9998 11.9493C12.2483 11.9493 12.4498 11.7478 12.4498 11.4993C12.4498 11.2508 12.2483 11.0493 11.9998 11.0493C11.7513 11.0493 11.5498 11.2508 11.5498 11.4993C11.5498 11.7478 11.7513 11.9493 11.9998 11.9493Z"
                                            fill="#000080" />
                                    </svg>
           <spam>Raipur</spam>
        </div>
    </header>
    <router-view />
    <BottomNavbar />

</template>
<script>
import BottomNavbar from "@/components/BottomNavbar.vue";
export default {
 name: "MobilePage",
components: {
  BottomNavbar,
},
};

</script>
