<template>
    <nav class="bottom-navbar" v-if="isMobile">
      <router-link to="/" class="active">
        <img :src="require('@/assets/images/icon/home-icon.svg')" alt="icon">
       <spam>Home</spam>
      </router-link>
      <a href="tel:+916263173842">
        <img :src="require('@/assets/images/icon/phone-icon.svg')" alt="icon">
        Call Us
      </a>
      <a href="https://wa.me/916263173842">
        <img :src="require('@/assets/images/icon/whatsapp-icon.svg')" alt="icon">
        WhatsApp
      </a>
    </nav>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isMobile: false,
      };
    },
    mounted() {
      this.checkScreenSize();
      window.addEventListener('resize', this.checkScreenSize);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.checkScreenSize);
    },
    methods: {
      checkScreenSize() {
        this.isMobile = window.innerWidth <= 768; // Show navbar on screens smaller than 768px
      },
    },
  };
  </script>
  
  <style>
  .bottom-navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .active{
    text-decoration: none;
    background-color: #393253;
    padding: 10px 20px;
    border-radius: 25px;
  }
  .active spam {
    color: #ffffff;
  }
  .bottom-navbar a {
    text-decoration: none;
    color: #393253;
    font-size: 13px;
    display: flex;
    align-items: center;
  }
  .bottom-navbar a:hover{
    color: #393253;
  }
  
  .bottom-navbar a img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  </style>
  