import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import the router

import './assets/styles.css'; // Import Tailwind or your custom CSS

const app = createApp(App);

app.use(router); // Use Vue Router in the app
app.mount('#app');
