<template>
    <div class="about-page">
      <header class="about-header">
        <h1>About Faster Home Service</h1>
        <p>Your Trusted Partner for Home Maintenance and Repair Services</p>
      </header>
      <section class="about-content">
        <div class="about-introduction">
          <img 
            src="@/assets/faster logo .webp" 
            alt="Faster Home Service Team" 
            class="about-image"
          />
          <div class="about-text">
            <h2>Who We Are</h2>
            <p>
              Faster Home Service is your one-stop solution for all home maintenance, repair, and installation services. 
              With a team of certified professionals, we provide over 110+ services at competitive prices. From appliance repair 
              to deep cleaning, we ensure quality, transparency, and customer satisfaction in every service we deliver.
            </p>
          </div>
        </div>
        <div class="about-values">
          <h2>Why Choose Us?</h2>
          <div class="values-grid">
            <div class="value-card">
              <img 
                src="@/assets/images/icon/Satisfaction-Guarantee.png" 
                alt="Satisfaction Guarantee Icon"
              />
              <h3>100% Satisfaction</h3>
              <p>We prioritize your satisfaction with free rework if our service doesn't meet your expectations.</p>
            </div>
            <div class="value-card">
              <img 
                src="@/assets/images/icon/Icons_stop_blau_new.svg" 
                alt="One-Stop Solution Icon"
              />
              <h3>One-Stop Solution</h3>
              <p>From AC repair to plumbing, find all your home service needs under one roof.</p>
            </div>
            <div class="value-card">
              <img 
                src="@/assets/images/icon/quality-assurance-17.png" 
                alt="Quality Assurance Icon"
              />
              <h3>Quality Assurance</h3>
              <p>Certified professionals deliver the highest standards of service every time.</p>
            </div>
            <div class="value-card">
              <img 
                src="@/assets/images/icon/eye-icon-png-viewed-accomms-10.png" 
                alt="Transparency Icon"
              />
              <h3>Transparency</h3>
              <p>Clear pricing and updates at every step of the process for a stress-free experience.</p>
            </div>
          </div>
        </div>
      </section>
      <footer class="about-footer">
        <h2>Our Mission</h2>
        <p>
          To revolutionize home services with professionalism, quality, and trust, making every home a happy place.
        </p>
      </footer>
    </div>
  </template>
<script>
export default {
  name: "AboutPage",
};
</script>
<style scoped>
.about-page {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.about-header {
  text-align: center;
  margin-bottom: 30px;
}

.about-header h1 {
  font-size: 32px;
  color: hwb(0 29% 71%);
}

.about-header p {
  font-size: 18px;
  color: #666;
}

.about-content {
  max-width: 1200px;
  margin: 0 auto;
}

.about-introduction {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
}

.about-image {
  flex: 1 1 300px;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-text {
  flex: 2;
}

.about-text h2 {
  font-size: 28px;
  color: hwb(0 29% 71%);
  margin-bottom: 10px;
}

.about-values {
  margin-top: 40px;
}

.about-values h2 {
  text-align: center;
  font-size: 28px;
  color: hwb(0 29% 71%);
  margin-bottom: 20px;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.value-card {
  text-align: center;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.value-card img {
  width: 50px;
  margin-bottom: 10px;
}

.value-card h3 {
  font-size: 20px;
  color: hwb(0 29% 71%);
  margin-bottom: 10px;
}

.value-card p {
  color: #555;
  font-size: 14px;
}

.about-footer {
  text-align: center;
  margin-top: 40px;
  background: #0073e6;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.about-footer h2 {
  margin-bottom: 10px;
  font-size: 24px;
}

.about-footer p {
  font-size: 16px;
}
</style>
  